import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  Select,
  Pagination,
  InputNumber,
  Dropdown,
  Option,
  Card,
  message,
  Tooltip,
  Modal,
  Collapse,
  Upload,
  notification,
  Popover,
  Badge,
  Spin,
} from "antd";
import {
  MoreOutlined,
  LoadingOutlined,
  CloudUploadOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import CoachesNote from "./coaches-note";
import "./coaches.css";
import cnImg from "../../../assets/img/import.png";
import evaluateImg from "../../../assets/img/clipboard-check.png";
import importImg from "../../../assets/img/notes.png";
import clipImg from "../../../assets/img/clipboard-check.png";
import brainWhite from "../../../assets/img/brain-electricitywhite.svg";
import editImg from "../../../assets/img/schedule/edit.svg";
import deleteImg from "../../../assets/img/schedule/delete.svg";
import pnImg from "../../../assets/img/schedule/push_notification.svg";
import note from "../../../assets/img/notes.png";
import ResultModal from "./ResultModal";
import EventModal from "./EventModal";
import PushNotificationModal from "../../../components/Modals/PushNotificationModal";
import DeleteModals from "./DeletesModal";
import SeasonsListSelect from "../../../components/seasonsListSelect";
import IndicatorMax from "./IndicatorMax";
import ScoutingReportModal from "./scouting-report-modal";
import binocular from "../../../assets/img/binocular.png";
import download from "../../../assets/img/download.svg";
import stats from "../../../assets/img/stats-square-up.svg";
import crystal from "../../../assets/img/codepen.svg";
import userSquare from "../../../assets/img/user-square-alt.svg";
import clipimg from "../../../assets/img/clipboard-check.svg";
import game from "../../../assets/img/grouppeople.svg";

const CoachEvaluation = (props) => {
  const keys = [
    "offensive_skills_of_the_week",
    "offensive_lineman_of_the_week",
    "defensive_skills_of_the_week",
    "defensive_lineman_of_the_week",
    "special_teams_of_the_week",
    "hit_man_of_the_week",
  ];

  const defaultAwardTitles = {
    offensive_skills_of_the_week: "Offensive Skills Player of the week",
    offensive_lineman_of_the_week: "Offensive Lineman of the week",
    defensive_skills_of_the_week: "Defensive Skills Player of the week",
    defensive_lineman_of_the_week: "Defensive Lineman of the week",
    special_teams_of_the_week: "Special Teams Player of the week",
    hit_man_of_the_week: "Hit Man of the week",
  };

  const getParsedTitles = (titles) => {
    if (!titles) return [];
    try {
      return JSON.parse(titles);
    } catch (error) {
      return [];
    }
  };

  const awardTitlesArray = getParsedTitles(
    props?.coachProfile?.school?.wardbord_column_titles || "[]"
  );

  const initialAwardTitles = keys.reduce((acc, key, index) => {
    acc[key] = awardTitlesArray[index] || defaultAwardTitles[key];
    return acc;
  }, {});

  const [gameweek, setGameweek] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false);
  const [TableData, setTableData] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [gamesList, setGamesList] = useState([]);
  const [gamesListMeta, setGamesListMeta] = useState({});
  const [gamesListLoading, setGamesListLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [gradesList, setGradesList] = useState([]);
  const [schoolsList, setSchoolsList] = useState([]);
  const [schoolsListMeta, setSchoolsListMeta] = useState({});
  const [columnTitles, setColumnTitles] = useState(initialAwardTitles);

  const [gradesListLoading, setGradesListLoading] = useState(false);

  const [indicators, setIndicators] = useState([]);

  const [indicatorsLoading, setIndicatorsLoading] = useState(false);

  const [indicatorMax, setIndicatorMax] = useState({});

  const [indicatorMaxLoading, setIndicatorMaxLoading] = useState(false);

  const [coachesNoteGameId, setCoachesNoteGameId] = useState(1);

  const [seasonsList, setSeasonsList] = useState([]);

  // result modal state
  const [resultModal, setResultModal] = useState(false);

  // update result modal state
  const [updateResult, setUpdateResult] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);
  const [eventId, setEventId] = useState("");
  const [deleteEventLoading, setDeleteEventLoading] = useState(false);
  const [scoutingModal, setScoutingModal] = useState(false);

  // edit event modal state
  const [editEvent, setEditEvent] = useState(false);
  const [editEventItem, setEditEventItem] = useState({});

  const [currentSeasonID, setCurrentSeasonID] = useState(null);
  //create event moda state
  const [createEvent, setCreateEvent] = useState(false);
  const [toggleItem, setToggleItem] = useState("");

  let history = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [gameItem, setGameItem] = useState({});

  const toggleAddResultModal = (val) => {
    setResultModal(!resultModal);
    setEditEventItem(val);
  };

  const toggleScoutingModal = (val) => {
    setScoutingModal(!scoutingModal);
    setEditEventItem(val);
  };

  const toggleUpdateResultModal = (val) => {
    setUpdateResult(!updateResult);
    setEditEventItem(val);
  };

  const toggleEditEventModal = (val) => {
    setEditEventItem(val);
    setEditEvent(!editEvent);
  };

  // wardbord screens coachesNoteGameId,ponent
  const [wardboardnotification, setWardboardNotification] = useState(false);
  const [selectedGame, setSelectedGame] = useState(false);
  const [allAthletesList, setAllAthletesList] = useState([]);
  const [rawMvpList, setRawMvpList] = useState([]);
  const [mvpList, setMvpList] = useState({});

  const openNotification = (game) => {
    const allData = sortSkills(game.game_id);
    setMvpList(allData);
    setSelectedGame(game);
    setIsNotificationModalVisible(true);
  };
  const closeNotification = () => {
    setWardboardNotification(false);
  };

  const loadAllAthletesList = () => {
    props.list({
      params: {
        limit: 1000,
        page: 1,
        expand: "athlete",
        athlete_profile_school: _.get(props, "coachProfile.school.id"),
      },
      callback: (res, data) => {
        if (res == true && _.size(_.get(data, "data.items", [])) > 0) {
          setAllAthletesList(data?.data?.items);
        }
      },
    });
  };

  const fetchMvplist = () => {
    props.MvpList({
      params: {
        expand: "athlete",
        season: currentSeasonID,
        limit: 1000,
        school: _.get(props, "coachProfile.school.id"),
      },

      callback: (res, data) => {
        if (res === true) {
          setRawMvpList(data?.data?.items);
        }
      },
    });
  };

  const createAnMvp = (title, week, athlete, game) => {
    props.CreateMvp({
      body: {
        athlete,
        coach: _.get(props, "coachProfile.id"),
        school: _.get(props, "coachProfile.school.id"),
        game,
        title,
        description: title,
        season: currentSeasonID,
        week,
      },
      callback: (res, data) => {
        if (res === true) {
          fetchMvplist();
        }
      },
    });
  };

  const removeAnMvp = (id) => {
    props.RemoveMvp({
      params: {
        status: "deleted",
        id,
      },
      body: {
        status: "0",
      },
      callback: (res, data) => {
        if (res === true) {
          fetchMvplist();
        }
      },
    });
  };

  const sortSkills = (game) => {
    const eachSkillsInaweek = rawMvpList.filter((item) => item.game == game);
    const checkExists = (description) => {
      const filteredItems = eachSkillsInaweek.filter(
        (item) => item.description.toLowerCase() == description.toLowerCase()
      );

      return filteredItems.length > 0 ? filteredItems : null;
    };

    let defaultToBeMapped = {
      Special_Teams_Player_of_the_week:
        checkExists("Special Teams Player of the week") || [],
      Hit_Man_of_the_week:
        checkExists("Hit Man of the week" || "hit man award") || [],
      Defensive_Lineman_of_the_week:
        checkExists("Defensive Lineman of the week") || [],
      Offensive_Skills_Player_of_the_week:
        checkExists("Offensive Skills Player of the week") || [],
      Defensive_Skills_Player_of_the_week:
        checkExists("Defensive Skills Player of the week") || [],
      Offensive_Lineman_of_the_week:
        checkExists("Offensive Lineman of the week") || [],
    };

    return defaultToBeMapped;
  };

  useEffect(() => {
    fetchMvplist();
  }, [currentSeasonID, notification]);

  useEffect(() => {
    if (_.get(props, "coachProfile.id")) {
      loadAllAthletesList();
    }
  }, []);
 

  // function to open notification toast
  const openNotificationModal = () => {
    notification.open({
      message: (
        <div className=" flex items-center font-[400] py-[5px] pl-[4px] pr-[45px] border-r-[1px] border-r-[white] border-r-solid">
          <span className="text-white text-[18px] ">
            Push Notification Successful
          </span>
        </div>
      ),
      icon: <img src={brainWhite} style={{ width: "30px" }} />,
      closeIcon: (
        <button
          className="text-base font-[200] h-[50px] pt-[12px] flex items-center justify-center text-white outline-none border-none"
          style={{ outline: "none" }}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      ),
      style: {
        backgroundColor: "#00B21E",
        color: "white",
      },
    });
    setIsNotificationModalVisible(false);
  };

  const handleUpdateEvent = (body) => {
    setGamesListLoading(true);
    props.updateGame({
      body,
      params: {
        id: editEventItem?.game_id,
      },
      callback: (res, data) => {
        if (res === true) {
          if (toggleItem === "update result") {
            toggleUpdateResultModal(null);
          } else if (toggleItem === "add result") {
            toggleAddResultModal(null);
          } else {
            toggleEditEventModal(null);
          }
          loadGamesList(currentSeasonID);
          handleGamesGrading(editEventItem);
          message.success("Game has been updated successfully");
        }
        setGamesListLoading(false);
      },
    });
  };

  const handleUpdateEventSmart = (body) => {
    setGamesListLoading(true);
    props.updateGame({
      body,
      params: {
        id: editEventItem?.game_id,
      },
      callback: (res, data) => {
        if (res === true) {
          if (toggleItem === "update result") {
            toggleUpdateResultModal(null);
          } else if (toggleItem === "add result") {
            toggleAddResultModal(null);
          } else {
            toggleEditEventModal(null);
          }
          loadGamesList(currentSeasonID);
          message.success("Game has been updated successfully");
        }
        setGamesListLoading(false);
      },
    });
  };

  const loadSchoolsList = (limit = 10000, page = 1) => {
    // setSchoolsListLoading(true)
    props.schoolsList({
      params: { limit },
      callback: (res, data) => {
        if (res === true) {
          let push_data = [];
          if (
            _.get(props, "coachProfile.school.id") &&
            _.get(data, "data.items[0].id")
          ) {
            setSchoolsListMeta(_.get(data, "data.meta", {}));
            _.map(_.get(data, "data.items", []), (value, index) => {
              if (
                _.get(value, "school_admin") !=
                _.get(props, "coachProfile.school.school_admin")
              ) {
                push_data.push(value);
              }
            });
            push_data.sort((a, b) => a.name.localeCompare(b.name));
            setSchoolsList(push_data);
          }
        }
        // setSchoolsListLoading(false)
      },
    });
  };

  const columns = [
    {
      title: "Week #",
      dataIndex: "week",
      align: "center",
      width: "10%",
      className: "p-2 py-4",
      key: "week",
      render: (text, item) => text.replace("Week ", ""),
    },

    {
      title: "Opponent",
      dataIndex: "title",
      width: "20%",
      align: "center",
      key: "title",
      render: (text, item) => (
        <div className="text-[10px] lg:text-[11.5px] font-inter font-normal">
          <span
            style={{
              textTransform: "lowercase",
              marginRight: "0.8%",
              color: "#A40E17",
            }}
            className="font-bold"
          >
            {" "}
            vs{" "}
          </span>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Event Type",
      dataIndex: "type",
      align: "center",
      width: "15%",
      key: "type",
    },
    {
      title: "Date",
      dataIndex: "start_date",
      align: "center",
      width: "12%",
      key: "start_date",
      className: "p-0",
      render: (date, item) => (
        <span
          className="text-[10px] lg:text-[11.5px] font-inter font-normal"
          onClick={() => {
            console.log(item.id);
          }}
        >
          {date}
        </span>
      ),
    },

    {
      title: "Scouting Report",
      dataIndex: "start_time",
      align: "center",
      width: "10%",
      //  className: 'p-0',
      key: "start_time",
      render: (text, item) => {
        return (
          <span className=" cursor-pointer flex items-center gap-2">
            <Tooltip title="View Report">
              <img
                src={binocular}
                className="w-[20px] "
                onClick={() => {
                  history(`/scouting-report?id=${_.get(item, "game_id")}`, {
                    state: { item: item },
                  });
                }}
              />
            </Tooltip>
            <Tooltip title="View Report">
              <CloudUploadOutlined
                style={{ fontSize: "20px" }}
                onClick={() => {
                  toggleScoutingModal(item);
                }}
              />
            </Tooltip>
          </span>
        );
      },
    },
    {
      //  className: 'p-0',
      title: "Feedback Type",
      dataIndex: "feedback_type",
      width: "12%",
      align: "center",
      key: "feedback_type",
      render: (text, item) => {
        const _labels = {
          feedback: "feedback Only",
          analytical: "Stat Mode",
          grading: "Grade Mode",
        };
        return _labels[text] || "Stat Mode";
      },
    },

    {
      title: "Evaluations",
      dataIndex: "feedback_type",
      width: "11%",
      align: "center",
      className: "p-0",
      key: "feedback_type",
      render: (text, item) => {
 

        const _labels = {
          feedback: "feedback Only",
          analytical: "Stat Mode",
          grading: "Grade Mode",
        };
        return (
          <div className="flex flex-col w-[90%] mx-auto">
            <div className="table-actions flex items-center w-full mt-3">
              <Tooltip title="Evaluate">
                <img
                  src={clipImg}
                  className="w-[23%] cursor-pointer"
                  onClick={() =>
                    history(
                      `/season-plays-list?id=${_.get(
                        item,
                        "game_id"
                      )}&opponent=${item.title}&team=${item.team}&week=${
                        item.week
                      }&t=${item.feedback_type || "analytical"}`
                    )
                  }
                />
              </Tooltip>

              <Tooltip
                title={
                  <>
                    {item.total_marked_as_read ? (
                      <>
                        {" "}
                        <p className="text-center mb-0 mt-1">
                          Player Assignments
                        </p>
                        <div className=" bg-green-500 text-white rounded-full text-[10px] flex items-center justify-center  p-2">
                          {item?.total_marked_as_read} of {item?.total_grades} (
                          {(
                            (item?.total_marked_as_read / item?.total_grades) *
                            100
                          ).toFixed()}
                          %) Marked as read
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {item?.total_player_responses ? (
                      <>
                        <p className="text-center mb-0 mt-1">
                          {" "}
                          Player Responses
                        </p>
                        <div
                          className={`  bg-blue-500 text-white rounded-full text-[10px] flex items-center justify-center  p-2`}
                        >
                          {item?.total_player_responses} comments submitted
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <p className="text-center mb-0 mt-1"> Coaches Notes </p>
                  </>
                }
              >
                <div className="w-[23%]">
                  <img
                    src={note}
                    alt="note-icon"
                    className="w-full cursor-pointer relative"
                    onClick={() => {
                      history(
                        `/coaches-note?game_id=${_.get(
                          item,
                          "game_id"
                        )}&opponent=${item.title}&team=${item.team}&week=${
                          item.week
                        }&date=${item.created_at}&t=${
                          item.feedback_type || "analytical"
                        }`
                      );
                    }}
                  />
                  <div className="flex relative w-[30%]">
                    {item?.total_marked_as_read ? (
                      <div
                        className={`absolute bottom-[15px] left-[20px] ${
                          (
                            (item?.total_marked_as_read / item?.total_grades) *
                            100
                          ).toFixed() == "100"
                            ? "bg-green-500"
                            : "bg-red-500 animate-bounce"
                        }  p-[2px] text-white rounded-full text-[10px] flex items-center justify-center `}
                      >
                        {(
                          (item?.total_marked_as_read / item?.total_grades) *
                          100
                        ).toFixed()}
                        %
                      </div>
                    ) : (
                      ""
                    )}

                    {indicatorsLoading ? (
                      <div className="absolute bottom-[15px] left-[20px] ">
                        {" "}
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Tooltip>
              <Tooltip title="More">
                <Popover
                  arrow={false}
                  trigger="click"
                  placement="bottom"
                  content={
                    <div className="flex flex-col w-[200px]">
                      <div
                        className="flex cursor-pointer items-center w-full text-[12px] font-inter"
                        onClick={() =>
                          history(
                            `/manage-playlist?id=${_.get(
                              item,
                              "game_id"
                            )}&opponent=${item.title}&team=${item.team}&week=${
                              item.week
                            }&t=${item.feedback_type || "analytical"}`
                          )
                        }
                      >
                        <img src={cnImg} className=" w-[8%]" />
                        <span className="ml-[3%]">Import Playlist</span>
                      </div>
                      <div
                        className="flex mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                        onClick={() => {
                          setToggleItem("edit event");
                          toggleEditEventModal(item);
                        }}
                      >
                        <img src={editImg} className=" w-[8%]" />
                        <span className="ml-[3%]">Edit Event</span>
                      </div>
                      <div
                        className="flex  mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                        onClick={() => {
                          setEventId(item.game_id);
                          setDeleteEvent(true);
                        }}
                      >
                        <img src={deleteImg} className=" w-[8%]" />
                        <span className="ml-[3%]">Delete Event</span>
                      </div>

                      <div
                        className="flex  mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                        onClick={() => {
                          handleGamesGrading(item);
                        }}
                      >
                        <NotificationOutlined />
                        <span className="ml-[3%]">Send Push Notifications</span>
                      </div>
                    </div>
                  }
                >
                  <MoreOutlined style={{ fontSize: "25px" }} />
                </Popover>
              </Tooltip>
            </div>
            <span className="font-bold text-[10px] text-center mt-1">
              Type:{" "}
              <span className="font-medium">
                {_labels[text] || "Stat Mode"}
              </span>
            </span>{" "}
          </div>
        );
      },
    },

    {
      title: "Results",
      dataIndex: "start_time",
      align: "center",
      key: "results",
      width: "11%",
      className: "p-0",
      render: (text, item) => (
        <>
          {item.home_team_score === 0 && item.away_team_score === 0 ? (
            <>N/A</>
          ) : (
            ""
          )}
          {item.home_team_score !== 0 || item.away_team_score !== 0 ? (
            <div
              className={
                item.home_team_score - item.away_team_score <= 0
                  ? "bg-[#A40E171A] h-full flex justify-center items-center text-[10px] lg:text-[11.5px]"
                  : "bg-[#12CF521A] h-full flex justify-center items-center text-[10px] lg:text-[11.5px]"
              }
            >
              <span className="font-inter font-bold text-[10px] lg:text-[11.5px]">
                {item.home_team_score - item.away_team_score <= 0 ? "L:" : "W:"}{" "}
              </span>

              <span className="ml-[2%] text-[10px] lg:text-[11.5px]">
                {item.home_team_score}
              </span>
              <span>-</span>

              <span className="text-[10px] lg:text-[11.5px]">
                {item.away_team_score}
              </span>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  const Admincolumns = [
    {
      title: "Week",
      dataIndex: "week",

      align: "center",
      width: "5%",
      className: "p-2 py-4",
      key: "week",
      render: (text, item) => text.replace("Week ", ""),
    },

    {
      title: "Team",
      dataIndex: "title",
      align: "center",
      width: "20%",
      key: "title",
      render: (text, item) => (
        <div className="text-[11px] font-inter font-normal">
          <span
            style={{
              textTransform: "lowercase",
              marginRight: "0.8%",
              color: "#A40E17",
            }}
            className="font-bold"
          >
            {" "}
            vs{" "}
          </span>
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Event Type",
      dataIndex: "type",
      align: "center",
      width: "11%",
      //  className: 'p-0',
      key: "type",
      // render: (text, record) => (
      //   <div className='adminTableSelect'>
      //     <Select
      //       defaultValue={text}
      //       dropdownMatchSelectWidth={true}
      //       style={{ width: "100%" }}
      //       className='adminTableSelect'
      //       bordered={false}
      //       onChange={(value) => console.log(value)}>
      //       <Option value='Scrimmage'>Scrimmage</Option>
      //       <Option value='Game'>Game</Option>
      //       <Option value='Practice'>Practice</Option>
      //     </Select>
      //   </div>
      // ),
    },
    {
      title: "Date",
      dataIndex: "start_date",
      align: "center",
      width: "9%",
      key: "start_date",
      className: "p-0",
      render: (date, item) => (
        <span
          className="text-[10px] lg:text-[11.5px] cursor-pointer font-inter font-normal"
          onClick={() => {
            console.log(item.id);
          }}
        >
          {item.start_date}
        </span>
      ),
    },
    {
      title: "Scouting Report",
      dataIndex: "start_time",
      align: "center",
      width: "8%",
      //  className: 'p-0',
      key: "start_time",
      render: (text, item) => {
        return (
          <span className=" cursor-pointer flex items-center justify-center gap-2">
            <Tooltip title="View Report">
              <img
                src={binocular}
                className="w-[20px] "
                onClick={() => {
                  history(`/scouting-report?id=${_.get(item, "game_id")}`, {
                    state: { item: item },
                  });
                }}
              />
            </Tooltip>
            <Tooltip title="Upload Report">
              <CloudUploadOutlined
                style={{ fontSize: "20px" }}
                onClick={() => {
                  toggleScoutingModal(item);
                }}
              />
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: "Evaluations",
      dataIndex: "feedback_type",
      width: "11%",
      align: "center",
      className: "p-0",
      key: "feedback_type",
      render: (text, item) => {
        const _labels = {
          feedback: "feedback Only",
          analytical: "Stat Mode",
          grading: "Grade Mode",
        };
        return (
          <div className="flex flex-col w-[90%] mx-auto">
            <div className="table-actions flex items-center w-full mt-3">
              <Tooltip title="Evaluate">
                <img
                  src={clipImg}
                  className="w-[23%] cursor-pointer"
                  onClick={() =>
                    history(
                      `/season-plays-list?id=${_.get(
                        item,
                        "game_id"
                      )}&opponent=${item.title}&team=${item.team}&week=${
                        item.week
                      }&t=${item.feedback_type || "analytical"}`
                    )
                  }
                />
              </Tooltip>

              <Tooltip
                title={
                  <>
                    {item.total_marked_as_read ? (
                      <>
                        {" "}
                        <p className="text-center mb-0 mt-1">
                          Player Assignments
                        </p>
                        <div className=" bg-green-500 text-white rounded-full text-[10px] flex items-center justify-center  p-2">
                          {item?.total_marked_as_read} of {item?.total_grades} (
                          {(
                            (item?.total_marked_as_read / item?.total_grades) *
                            100
                          ).toFixed()}
                          %) Marked as read
                        </div>
                      </>
                    ) : (
                      ""
                    )} 
                    {item?.total_player_responses ? (
                      <>
                        <p className="text-center mb-0 mt-1">
                          {" "}
                          Player Responses
                        </p>
                        <div
                          className={`  bg-blue-500 text-white rounded-full text-[10px] flex items-center justify-center  p-2`}
                        >
                          {item?.total_player_responses} comments submitted
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <p className="text-center mb-0 mt-1"> Coaches Notes </p>
                  </>
                }
              >
                <div className="w-[23%]">
                  <img
                    src={note}
                    alt="note-icon"
                    className="w-full cursor-pointer relative"
                    onClick={() => {
                      history(
                        `/coaches-note?game_id=${_.get(
                          item,
                          "game_id"
                        )}&opponent=${item.title}&team=${item.team}&week=${
                          item.week
                        }&date=${item.created_at}&t=${
                          item.feedback_type || "analytical"
                        }`
                      );
                    }}
                  />
                  <div className="flex relative w-[30%]">
                    {item?.total_marked_as_read ? (
                      <div
                        className={`absolute bottom-[15px] left-[20px] ${
                          (
                            (item?.total_marked_as_read / item?.total_grades) *
                            100
                          ).toFixed() == "100"
                            ? "bg-green-500"
                            : "bg-red-500 animate-bounce"
                        }  p-[2px] text-white rounded-full text-[10px] flex items-center justify-center `}
                      >
                        {(
                          (item?.total_marked_as_read / item?.total_grades) *
                          100
                        ).toFixed()}
                        %
                      </div>
                    ) : (
                      ""
                    )}

                    {indicatorsLoading ? (
                      <div className="absolute bottom-[15px] left-[20px] ">
                        {" "}
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          size="small"
                        />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Tooltip>
              <Tooltip title="More">
                <Popover
                  arrow={false}
                  trigger="click"
                  placement="bottom"
                  content={
                    <div className="flex flex-col w-[200px]">
                      <div
                        className="flex cursor-pointer items-center w-full text-[12px] font-inter"
                        onClick={() =>
                          history(
                            `/manage-playlist?id=${_.get(
                              item,
                              "game_id"
                            )}&opponent=${item.title}&team=${item.team}&week=${
                              item.week
                            }&t=${item.feedback_type || "analytical"}`
                          )
                        }
                      >
                        <img src={cnImg} className=" w-[8%]" />
                        <span className="ml-[3%]">Import Playlist</span>
                      </div>
                      <div
                        className="flex mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                        onClick={() => {
                          setToggleItem("edit event");
                          toggleEditEventModal(item);
                        }}
                      >
                        <img src={editImg} className=" w-[8%]" />
                        <span className="ml-[3%]">Edit Event</span>
                      </div>
                      <div
                        className="flex  mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                        onClick={() => {
                          setEventId(item.game_id);
                          setDeleteEvent(true);
                        }}
                      >
                        <img src={deleteImg} className=" w-[8%]" />
                        <span className="ml-[3%]">Delete Event</span>
                      </div>

                      <div
                        className="flex  mt-2 cursor-pointer items-center w-full text-[12px] font-inter"
                        onClick={() => {
                          handleGamesGrading(item);
                        }}
                      >
                        <NotificationOutlined />
                        <span className="ml-[3%]">Send Push Notifications</span>
                      </div>
                    </div>
                  }
                >
                  <MoreOutlined style={{ fontSize: "25px" }} />
                </Popover>
              </Tooltip>
            </div>
            <span className="font-bold text-[10px] text-center mt-1">
              Type:{" "}
              <span className="font-medium">
                {_labels[text] || "Stat Mode"}
              </span>
            </span>{" "}
          </div>
        );
      },
    },
    {
      title: "Results",
      dataIndex: "start_time",
      align: "center",
      key: "start_time",
      width: "10%",
      className: "p-0",
      render: (text, item) => (
        <>
          {item.home_team_score === 0 && item.away_team_score === 0 ? (
            <>
              <div
                className="text-center text-[#1A91FF] cursor-pointer underline text-[11px]"
                onClick={() => {
                  setToggleItem("add result");
                  toggleAddResultModal(item);
                }}
              >
                <span className="text-[#1A91FF] ">+ add result</span>
              </div>
            </>
          ) : (
            ""
          )}
          {item.home_team_score !== 0 || item.away_team_score !== 0 ? (
            <>
              <div
                className={
                  item.home_team_score - item.away_team_score <= 0
                    ? "bg-[#A40E171A] h-full flex justify-between items-center text-[11px] px-[8px]"
                    : "bg-[#12CF521A] h-full flex justify-between items-center text-[11px] px-[8px]"
                }
              >
                <div className="flex items-center">
                  <span className="font-inter font-bold text-[11px]">
                    {item.home_team_score - item.away_team_score <= 0
                      ? "L:"
                      : "W:"}{" "}
                  </span>

                  <span className="ml-[2%] text-[11px]">
                    {item.home_team_score}
                  </span>
                  <span>-</span>

                  <span className="text-[11px]">{item.away_team_score}</span>
                </div>
                <Tooltip title="Update Results">
                  <img
                    src={editImg}
                    className="w-[18px] cursor-pointer"
                    onClick={() => {
                      setToggleItem("update result");
                      toggleUpdateResultModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];
  const handleGamesGrading = (game) => {
    props.approveGameGrading({
      body: {
        status: 1,
        id: game?.school,
        title: "Wardbord Notifications",
        body: "Last game’s evaluation is now available! Go read your feedback from coach!",
      },
      callback: (res, data) => {
        if (res) {
          notification.success({
            message: "Success",
          });
          // setShowUpdateModal(false);
        }
      },
    });
  };
  //get user data
  const [userProfile, setUserProfile] = useState({});
  const deleteGame = (id) => {
    setDeleteEventLoading(true);
    props.changeStatus({
      body: { id, status: 3, status_glossary: "Deleted" },
      callback: (res, data) => {
        if (res == true && _.get(data, "details.id")) {
          notification.success({
            message: "Success",
            description: _.get(data, "message"),
          });
          setDeleteEventLoading(false);
          setDeleteEvent(false);
        }
        setDeleteEventLoading(false);
        loadGamesList(currentSeasonID);
        setDeleteEvent(false);
      },
    });
  };
  useEffect(() => {
    loadSeasonsList();
    getGridironDetails();
    loadSchoolsList();
    //  _.get(props, "coachProfile.school.id");
    // setUserProfile(_.get(data, "data.details"));
  }, []);
  const getGridironDetails = () => {
    props.getGridironDetails({
      callback: (res, data) => {
        // if (res === true) {
        //   console.log('gridiron', data.data)
        // }
      },
    });
  };
  const Option = Select.Option;
  const { Panel } = Collapse;

  const loadSeasonsList = () => {
    const list = JSON.parse(sessionStorage.getItem("seasonsList") || "[]");
    if (list.length > 0) {
      setSeasonsList(list);
    }
    props.seasonsList({
      params: {
        sort: "id.desc",
        limit: 1000,
      },
      callback: (res, data) => {
        if (res === true) {
          const result = data?.data?.items;
          setSeasonsList(result);
          sessionStorage.setItem("seasonsList", JSON.stringify(result));
        }
      },
    });
  };

  useEffect(() => {
    if (_.get(props, "coachProfile.school.id") && seasonsList.length > 0) {
      loadGamesList();
    }
  }, [_.get(props, "coachProfile.school.id"), seasonsList]);

  const loadGamesList = (seasonID = null) => {
    try {
      const season = seasonID ?? seasonsList[0]?.id;
      if (season) {
        setCurrentSeasonID(season);
        let params = {
          limit: 995,
          home_team: _.get(props, "coachProfile.school.id"),
          sort: "week.asc",
          season,
        };
        const list = JSON.parse(
          sessionStorage.getItem(`${season}-gamesList`) || "[]"
        );

        if (list.length > 0) {
          setAllGamesList(list);
          setGamesListLoading(false);
        } else {
          setGamesListLoading(true);
        }
        props.gamesList({
          params: params,
          callback: (res, data) => {
            if (res === true) {
              sessionStorage.setItem(
                `${season}-gamesList`,
                JSON.stringify(_.get(data, "data.items", []))
              );
              setAllGamesList(_.get(data, "data.items", []));
              loadGamesIndicator();
              loadGamesIndicatorMax();
            }
            setGamesListLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  //gamesIndicator
  const loadGamesIndicator = () => {
    try {
      setIndicatorsLoading(true);
      let params = {
        games: gamesList.map((game) => game.id).toString(),
        coach_id: props?.coachProfile?.id,
      };
      props.gamesIndicator({
        params,
        callback: (res, data) => {
          if (res === true) {
            const result = data?.data?.result;
            const ids = result?.map((i) => i.game);
            if (result) {
              const games = gamesList.map((game) => {
                if (ids.includes(game?.game_id)) {
                  const value = result.filter((r) => r.game == game.game_id)[0];
                  game.total_grades_seen = value.total_grades_seen;
                  game.total_marked_as_read = value.total_marked_as_read;
                  game.total_player_responses = value.total_player_responses;

                  game.total_grades = value.total_grades;
                }

                return game;
              });
              console.log('games',games)
               setGamesList(games);
            }

            setIndicators(result);
          }

          setIndicatorsLoading(false);
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  //gamesIndicatorMax
  const loadGamesIndicatorMax = () => {
    try {
      setIndicatorMaxLoading(true);
      let params = {
        games: gamesList
          ?.filter((game) => game.type == "regular season")
          .map((game) => game.id)
          .toString(),
      };

      props.gamesIndicatorMax({
        params,
        callback: (res, data) => {
          if (res === true) {
            const { result } = data?.data;
            setIndicatorMax(result);
          }
          setIndicatorMaxLoading(false);
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // scout report
  const handleFetchScoutReport = async () => {
    try {
      await props.fetchScoutingReport({
        params: { game: "1114" },
        callback: (res, data) => {
          if (res === true) {
            console.log("Scouting report", data);
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setAllGamesList = (data) => {
    let weekFormat = "Week";
    let list = [];

    if (_.size(data) > 0) {
      _.map(data, (value, index) => {
        list.push({
          key: index,
          id: parseInt(value.id),
          title: _.startCase(_.get(value, "title")),
          season: _.get(value, "season"),
          start_date: _.get(value, "end_time")
            ? moment(`${_.get(value, "end_time")}`).format("MM/DD/YYYY")
            : "---",
          week: weekFormat.concat(" ", _.get(value, "week")),
          start_time: _.get(value, "start_time")
            ? moment(`${_.get(value, "start_time")}`).format("h:mm A")
            : "---",

          duration: _.get(value, "duration"),
          game_id: _.get(value, "id"),
          opponent_logo: value.opponent_logo,
          away_team_score: value.away_team_score ?? 0,
          home_team_score: value.home_team_score ?? 0,
          team: props.coachProfile?.school?.name,
          school: props.coachProfile?.school.id,
          type: value.type,
          feedback_type: value.feedback_type,
        });
      });

      setGamesList(list);
    } else {
      setGamesList([]);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setGradesList([]);

    setIsModalOpen(false);
  };

  const render = () => {
    return (
      <div className="w-[98%] h-full p-[2%] flex coachesEvaluationPage">
        <div className=" w-full">
          <div className="w-full ">
            <h6 className=" text-[16px] md:text-[24px] lg:text-[28px] font-poppins font-bold h-[10%] cursor-pointer">
              Dashboard
            </h6>
            {/* edit event modal */}
            <Modal
              open={showToast}
              // onCancel={handleClose}
              footer="toasted"
              closable={true}
              centered
            >
              {message}
            </Modal>
            <Card className="h-[90%] w-full" bodyStyle={{ padding: "24px" }}>
              <Modal
                width={1000}
                footer={null}
                title={null}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <CoachesNote
                  gradesList={gradesList}
                  gradesListLoading={gradesListLoading}
                  game={coachesNoteGameId}
                />
              </Modal>
              <div className="seasons flex  mb-[2%]   text-[14px] font-bold font-inter items-center">
                <div className="w-[350px]  flex">
                  <span className="my-auto">Season</span>
                  <SeasonsListSelect
                    onChange={(value) => {
                      setCurrentSeasonID(value);
                      loadGamesList(value);
                    }}
                  />
                  {_.get(props, "coachProfile.type") === 1 ? (
                    <div className="w-[100px] my-auto h-[40px] ml-[8px] pt-[5px] border-[1px] border-solid border-[#EAEDF0] font-bold font-inter flex items-center justify-center rounded-[4px]">
                      <Button
                        type="link"
                        style={{ color: "black" }}
                        className="   "
                        onClick={() =>
                          history(`/create-games?season=${currentSeasonID}`)
                        }
                      >
                        Create Event
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-full">
                  <IndicatorMax
                    data={indicatorMax}
                    indicatorMaxLoading={indicatorMaxLoading}
                    gamesList={gamesList}
                  />
                </div>
              </div>
              {/* check admin or not */}

              {deleteEvent === true && (
                <DeleteModals
                  onOpen={deleteEvent}
                  onCancel={() => setDeleteEvent(false)}
                  title="Clear Evaluation Data"
                  text="Are you sure you want to delete this game. This action cannot be undone."
                  onDelete={() => {
                    deleteGame(eventId);
                  }}
                  loading={deleteEventLoading}
                />
              )}

              {_.get(props, "coachProfile.type") === 1 ? (
                <div className="w-full admin-evaluationtable">
                  <Table
                    key={1}
                    bordered={true}
                    loading={gamesListLoading}
                    columns={Admincolumns}
                    dataSource={gamesList}
                    size="middle"
                    pagination={false}
                    className="w-[100%] h-full border-[1px] border-[#F3F5F7] border-[solid]"
                  />
                </div>
              ) : (
                <div className="w-full coacheval-table">
                  <Table
                    key={1}
                    bordered={true}
                    loading={gamesListLoading}
                    columns={columns}
                    dataSource={gamesList}
                    size="middle"
                    pagination={false}
                    className="w-[100%] h-full border-[1px] border-[#F3F5F7] border-[solid]"
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
        {editEvent ? (
          <EventModal
            onOpen={editEvent}
            onCancel={() => toggleEditEventModal(null)}
            title="Edit Event"
            game={editEventItem}
            buttonText="Save"
            handleUpdateEvent={handleUpdateEventSmart}
            schoolsList={schoolsList}
            loading={gamesListLoading}
          />
        ) : (
          ""
        )}
        <ResultModal
          onOpen={resultModal}
          onCancel={() => toggleAddResultModal(null)}
          title={`Add ${editEventItem?.week} Game Result`}
          game={editEventItem}
          buttonText="Next"
          handleUpdateEvent={handleUpdateEvent}
          setIsNotificationModalVisible={openNotification}
        />
        <ResultModal
          onOpen={updateResult}
          onCancel={() => toggleUpdateResultModal(null)}
          title={`Update ${editEventItem?.week} Game Result`}
          game={editEventItem}
          buttonText="Update"
          handleUpdateEvent={handleUpdateEvent}
        />

        {scoutingModal && (
          <ScoutingReportModal
            onOpen={scoutingModal}
            onCancel={() => toggleScoutingModal(null)}
            title={`Upload ${editEventItem?.week} Scouting Report`}
            game={editEventItem?.game_id}
            gridironDetails={props.gridironDetails}
            generateSignedUrl={props.generateSignedUrl}
            coach_id={_.get(props, "coachProfile.id")}
            school={_.get(props, "coachProfile.school.id")}
            uploadScoutingReport={props.uploadScoutingReport}
          />
        )}

        {/* push notification modal */}
        <PushNotificationModal
          open={isNotificationModalVisible}
          onCancel={() => {
            setIsNotificationModalVisible(false);
          }}
          onOk={openNotificationModal}
          buttonText="Push Notification"
          allAthletesList={allAthletesList}
          selectedGame={selectedGame}
          createAnMvp={createAnMvp}
          removeAnMvp={removeAnMvp}
          mvpList={mvpList}
          columnTitles={columnTitles}
        />
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => {
  return {
    coachProfile: _.get(state, "app.account.profile.data.details"),
    schoolDetails: _.get(state, "app.schools.view.data"),
    positionsData: state?.app?.site?.gridironDetails?.data?.data?.positions,
    gridironDetails: _.get(state, "app.site.gridironDetails.data.data", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  gamesIndicator: (details) =>
    dispatch(global.redux.action.games.indicator(details)),
  gamesIndicatorMax: (details) =>
    dispatch(global.redux.action.games.indicatorMax(details)),
  updateGame: (details) => dispatch(global.redux.action.games.update(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  seasonsList: (details) => dispatch(global.redux.action.seasons.list(details)),
  schoolsList: (details) => dispatch(global.redux.action.schools.list(details)),
  getGridironDetails: (details) =>
    dispatch(global.redux.action.site.gridironDetails(details)),
  changeStatus: (details) =>
    dispatch(global.redux.action.games.changeStatus(details)),
  MvpList: (details) => dispatch(global.redux.action.mvps.list(details)),
  MvpView: (details) => dispatch(global.redux.action.mvps.view(details)),
  list: (details) => dispatch(global.redux.action.athletes.infoList(details)),
  RemoveMvp: (details) =>
    dispatch(global.redux.action.mvps.changeStatus(details)),
  approveGameGrading: (details) =>
    dispatch(global.redux.action.grades.approve(details)),
  CreateMvp: (details) => dispatch(global.redux.action.mvps.create(details)),
  generateSignedUrl: (details) =>
    dispatch(global.redux.action.games.generateSignedUrl(details)),
  uploadScoutingReport: (details) =>
    dispatch(global.redux.action.games.uploadScoutingReport(details)),
  fetchScoutingReport: (details) =>
    dispatch(global.redux.action.games.fetchScoutingReport(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoachEvaluation);
