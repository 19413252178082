import React, { useState, useEffect, useRef } from "react";
import { Select, Card, message } from "antd";
import threeDot from "../../assets/img/Frame 18264.svg";
import { Alert } from "antd";
import SeasonsListSelect from "../../components/seasonsListSelect";

import DefensiveStatsTable from "./defensiveStatsTable";
import { connect } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import send from "../../assets/img/send.svg";

import "./seasondashboard.css";
import Statistics from "./modal/statistics";
import OStatsTable from "./oStatsTable";
import DStatsTable from "./dStatsTable";
import StStatsTable from "./stStats";
import KickerStatsTable from "./kickerStatsTable";
import { useNavigate } from "react-router-dom";
import ExportCsvModal from "./modal/exportCsvModal";
import ButtonUI from "../../components/ButtonUI";
import { oColumns, kickerColumns, dColumns, stColumns } from "./statsColumns";
import sendBlack from "../../assets/img/Vector (Stroke).svg";

const { Option } = Select;

function combineObjects(objArray) {
  const combined = {};
  objArray.forEach((obj) => {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const val = Number(obj[key]);
        if (!isNaN(val) && key != "kit_number") {
          combined[key] = (combined[key] || 0) + val;
        } else {
          if (!combined.hasOwnProperty(key)) {
            combined[key] = obj[key];
          }
        }
      }
    }
  });

  return { ...combined, athlete: combined.athlete / objArray.length };
}
function Stats(props) {
  const contentRef = useRef(null);
  let positionsMap = {
    d: ["DL", "LB", "S", "C"],
    o: ["OL", "QB", "RB", "TE", "WR"],
    st: ["ST"],
    kicker: ["K"],
  };
  const [seasonsList, setSeasonsList] = useState([]);
  const [seasonID, setSeasonID] = useState(null);
  const [gamesListLoading, setGamesListLoading] = useState(false);
  const [gamesList, setGamesList] = useState([]);
  const [game, setGame] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [theirTeam, setTheirTeam] = useState("All");
  const [odk, setOdk] = useState("o");
  const [dataSource, setDataSource] = useState([]);
  const [rawDataSource, setRawDataSource] = useState([]);
  const [positionsArray, setPositionsArray] = useState(positionsMap[odk]);
  const [showModal, setShowModal] = useState(false);
  const [kickerDatasource, setKickerDataSource] = useState([]);
  const [column, setColumn] = useState(oColumns);
  const [positions, setPositions] = useState("All");

  const history = useNavigate();
  const handleSortPlay = (type) => {
    setOdk(type);
    setPositionsArray(positionsMap[type]);
  };

  useEffect(() => {
    if (props?.coachProfile?.school?.id) {
      loadGamesList();
    }
  }, [seasonsList, seasonID]);

  const loadGamesList = () => {
    try {
      const season = seasonID ?? seasonsList[0]?.id;

      if (season) {
        setSeasonID(season);

        let params = {
          limit: 995,
          home_team: props?.coachProfile?.school?.id,
          sort: "week.asc",
          season,
        };
        const list = JSON.parse(
          sessionStorage.getItem(`${seasonID}-gamesList`)
        );

        if (list) {
          setGamesList(list);
          setGamesListLoading(false);
        } else {
          setGamesListLoading(true);
        }

        props?.gamesList({
          params: params,
          callback: (res, data) => {
            if (res === true) {
              setGamesList(data?.data?.items);
              // setAllGamesList(data?.data?.items);
              const game = data?.data?.items.filter(
                (game) =>
                  game.type == "regular season" || game.type == "playoffs"
              );
              setGame(game.map(({ id }) => id));
              setTheirTeam("Season Stats");
            } else {
              setGamesList([]);
              setGame([]);
            }
            setGamesListLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (game.length > 0) {
      loadGradesStats(JSON.stringify(game), JSON.stringify(positionsArray));
    }
  }, [game, positionsArray, odk]);

  const loadGradesStats = (game, position) => {
    try {
      if (game && position) {
        setPageLoading(true);
        props.gradesStats({
          params: {
            game,
            position,
          },
          callback: (res, data) => {
            if (res) {
              const dupChecker = {};
              const duppies = new Set();
              let theDataStore = [];
              const theData = data?.data?.items?.rows;

              console.log('theData actual theData ',theData )

              setRawDataSource(theData)

              theData?.map((data) => {
                if (!dupChecker[data.athlete]) {
                  dupChecker[data.athlete] = 1;
                } else {
                  dupChecker[data.athlete] += 1;
                  duppies.add(data.athlete);
                }
              });

              if (duppies.size) {
                duppies?.forEach((duppy) => {
                  const duplicates = theData?.filter((d) => d.athlete == duppy);
                  theDataStore.push(combineObjects(duplicates));
                });

                theData.map((d) => {
                  if (!duppies.has(d.athlete)) {
                    theDataStore.push(d);
                  }
                });
              } else {
                theDataStore = theData;
              }
              const sortedData = [...theDataStore]?.sort((a, b) => {
                let A;
                let B;
                if (odk == "o") {
                  A =
                    Number(a.total_completions_carries_yds) +
                    Number(a.total_complete_receptions_yds) +
                    Number(a.total_gn_carries_yds);
                  B =
                    Number(b.total_completions_carries_yds) +
                    Number(b.total_complete_receptions_yds) +
                    Number(b.total_gn_carries_yds);
                }
                if (odk == "d") {
                  A =
                    Number(a.total_tackles_solo) +
                    Number(a.total_tackles_ast_halved) -
                    Number(a.total_missed_tackles);
                  B =
                    Number(b.total_tackles_solo) +
                    Number(b.total_tackles_ast_halved) -
                    Number(b.total_missed_tackles);
                }
                if (odk == "st") {
                  A =
                    Number(a.total_punt_return_yds) +
                    Number(a.total_kickoff_return_yds);
                  B =
                    Number(b.total_punt_return_yds) +
                    Number(b.total_kickoff_return_yds);
                }
                if (B !== A) {
                  return B - A;
                } else {
                  return a.id - b.id;
                }
              });
              setDataSource(sortedData || []);
              if (odk == "kicker") {
                const sortedData = [...theDataStore]?.sort((a, b) => {
                  const A = Number(a.plays);
                  const B = Number(b.plays);
                  if (B !== A) {
                    return B - A;
                  } else {
                    return a.id - b.id;
                  }
                });
                setKickerDataSource(sortedData || []);

             
                setDataSource(sortedData || []);
              }
              console.log('actual sortedData ',sortedData )
            }
            setPageLoading(false);
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePrint = () => {
    if (!dataSource.length) {
      message.error("No data to print");
      return;
    }
    history("/stats-to-print", {
      state: {
        dataSource: dataSource,
        season: seasonID,
        odk: odk,
        theirTeam: theirTeam,
        type: "stats",
      },
    });
  };

  return (
    <>
      <div
        className="flex flex-col font-inter lg:w-[99%] lg:p-[1%] stats-table-con"
        ref={contentRef}
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center w-[80%]">
            <div className="px-2 bg-[black] w-[325px] h-[48px] font-poppins flex items-center rounded-[4px] mr-2">
              <button
                onClick={() => {
                  setPositions('All')
                  handleSortPlay("o");
                  
                }}
                className={
                  (odk == "o" ? " bg-[#A40E17]" : " ") +
                  " w-[32px] h-[31px] cursor-pointer rounded-[4px] mr-[10px] text-[#FFFFFF] hover:bg-[#A40E17]"
                }
              >
                O
              </button>
              <button
                onClick={() => {
                  setPositions('All')
                  handleSortPlay("d");
                }}
                className={
                  (odk == "d" ? " bg-[#A40E17]" : " ") +
                  " w-[32px] h-[31px] cursor-pointer mr-[10px] rounded-[4px] text-[#FFFFFF] hover:bg-[#A40E17]"
                }
              >
                D
              </button>
              <button
                onClick={() => {
                  setPositions('All')
                  handleSortPlay("st");
                }}
                className={
                  (odk == "st" ? " bg-[#A40E17]" : " ") +
                  " w-[32px] h-[31px] cursor-pointer mr-[10px]  rounded-[4px] text-[#FFFFFF] hover:bg-[#A40E17]"
                }
              >
                ST
              </button>
              <button
                onClick={() => {
                  setPositions('All')
                  handleSortPlay("kicker");
                }}
                className={
                  (odk == "kicker" ? " bg-[#A40E17]" : " ") +
                  " w-[70px] h-[31px] cursor-pointer  rounded-[4px] text-[#FFFFFF] hover:bg-[#A40E17]"
                }
              >
                Kicker
              </button>

              <button
                onClick={() => {
                  history("/scoring");
                }}
                className={
                  "hover:bg-[#A40E17] w-[100px] h-[31px] cursor-pointer flex justify-center items-center rounded-[4px] text-[#FFFFFF]"
                }
              >
                <span> Scoring</span>
              </button>
            </div>
            <div className="w-[200px] h-[43px] bg-[#ffffff] mt-[1px] border-[1px] border-solid border-[#EAEDF0] font-bold flex items-center justify-center rounded-[4px]">
              <Select
                bordered={false}
                placeholder={"All Games"}
                className="w-[100%] text-[14px]"
                onChange={(id) => {
                  if (id === "all") {
                    const game = gamesList.filter(
                      (game) =>
                        game.type == "regular season" || game.type == "playoffs"
                    );
                    setGame(game.map(({ id }) => id));
                    setTheirTeam("Season Stats");
                  } else if (id === "scrimmages") {
                    const game = gamesList.filter(
                      (game) => game.type == "scrimmage"
                    );
                    setGame(game.map(({ id }) => id));

                    setTheirTeam("Scrimmages stats");
                  } else if (id === "practice") {
                    const game = gamesList.filter(
                      (game) => game.type == "practice"
                    );
                    setGame(game.map(({ id }) => id));

                    setTheirTeam("Practices stats ");
                  } else {
                    const game = gamesList.filter((game) => game.id == id)[0];
                    setGame([id]);

                    setTheirTeam(game.title);
                  }
                }}
              >
                <Option
                  value={"all"}
                  key={"all"}
                  className="text-[14px] font-bold font-inter"
                  disabled={!gamesList?.length}
                >
                  All Games
                </Option>
                <Option
                  value={"practice"}
                  key={"practice"}
                  className="text-[14px] font-bold font-inter"
                  disabled={
                    !gamesList.filter((game) => game.type == "practice").length
                  }
                >
                  Practices
                </Option>

                <Option
                  value={"scrimmages"}
                  key={"scrimmages"}
                  disabled={
                    !gamesList.filter((game) => game.type == "scrimmage").length
                  }
                  className="text-[14px] font-bold font-inter"
                >
                  Scrimmages
                </Option>

                {gamesList?.map(({ id, title, week, type }, i) => {
                  if (type == "regular season" || type == "playoffs")
                    return (
                      <Option
                        value={id}
                        key={title}
                        className="text-[14px] font-bold font-inter"
                      >
                        Week {week}
                      </Option>
                    );
                })}
              </Select>
            </div>
            <SeasonsListSelect
              setSeasonsList={setSeasonsList}
              seasonsLists={seasonsList}
              onChange={(e) => {
                setSeasonID(e);
              }}
            />
            <span className="text-[14px] font-normal mx-2">
              <span className="text-[#A40E17]"> vs</span> {theirTeam}
            </span>
          </div>
          {/* <img
            src={threeDot}
            className="bg-[#E8F4FF] rounded-lg py-2 px-3 mx-auto cursor-pointer"
          /> */}
          <div className=" w-[167px] lg:w-[18%] 2xl:w-[15%]">
            <ButtonUI
              text={"Send to Maxpreps"}
              textColor={"#2E4D6B"}
              background={"transparent"}
              leftIcon={sendBlack}
              buttonFunction={() => {
                history("/scoring");
              }}
            />
          </div>
        </div>

        <div className="mt-2 w-full flex flex-col" id="stats-table-con">
          <Card
            bodyStyle={{ padding: "12px", width: "100%" }}
            className="stats-table-con-two"
          >
            <div
              className="flex items-center justify-between w-full mb-2 hide-in-print"
              style={{ cursor: "pointer" }}
            >
            
              <div className="bg-[#F3F5F7] h-[48px] font-poppins flex items-center rounded-[4px] mr-[16px]">
             { odk == 'st' || odk == 'kicker' ? '' : (<> <button
                  className={
                    (positions == "All"
                      ? "bg-[#1A91FF] text-[#ffffff]"
                      : "text-[#1A91FF] ") +
                    " w-[43px] focus:outline-0 h-[48px]"
                  }
                  onClick={() => {
                    setPositions("All");
                  }}
                >
                  All
                </button>
                {positionsArray.map((position, key) => (
                  <button
                  key={key}
                    className={
                      (positions == position
                        ? "bg-[#1A91FF] text-[#ffffff]"
                        : "text-[#1A91FF] ") +
                      " w-[43px] focus:outline-0 h-[48px]"
                    }
                    onClick={() => {
                      setPositions(position);
                    }}
                  >
                    {position}
                  </button>
                ))}
                </>) }
              </div>
              <span
                className="text-right flex items-center"
                style={{ fontSize: "14px" }}
                onClick={handlePrint}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.73584 11.7188H15.2358M3.98584 6.46875V1.66875C3.98584 1.42022 4.18731 1.21875 4.43584 1.21875H12.5358C12.7844 1.21875 12.9858 1.42022 12.9858 1.66875V6.46875M15.2358 14.1188V10.4688C15.2358 8.25961 13.445 6.46875 11.2358 6.46875H5.73584C3.5267 6.46875 1.73584 8.25961 1.73584 10.4688V14.1187C1.73584 14.4501 2.00447 14.7188 2.33584 14.7188H14.6358C14.9672 14.7188 15.2358 14.4501 15.2358 14.1188Z"
                    stroke="#0F1A24"
                    strokeWidth="1.5"
                  />
                </svg>
                <span>&nbsp;&nbsp;Print Stats</span>
              </span>
            </div>
            <Card
              bodyStyle={{ padding: 0, width: "100%" }}
              className="w-full lg:overflow-scroll"
            >
              <div
                style={{ borderBottom: "1px solid #F3F5F7" }}
                className={`w-full  ${
                  odk == "o" ? "lg:min-w-[2000px]" : "lg:min-w-[2000px]"
                }`}
              >
                {console.log('dataSource',dataSource,positions)}
                {odk === "o" && (
                  <OStatsTable
                    dataSource={
                      positions == "All"
                        ? dataSource
                        : rawDataSource?.filter(
                            (data) => data.position == positions
                          )
                    }
                    pageLoading={pageLoading}
                  />
                )}
                {odk === "d" && (
                  <DStatsTable
                    dataSource={
                      positions == "All"
                        ? dataSource
                        : rawDataSource?.filter(
                            (data) => data.position == positions
                          )
                    }
                    pageLoading={pageLoading}
                  />
                )}

                {odk === "st" && (
                  <StStatsTable
                    dataSource={
                      positions == "All"
                        ? dataSource
                        : rawDataSource?.filter(
                            (data) => data.position == positions
                          )
                    }
                    pageLoading={pageLoading}
                  />
                )}
                {odk === "kicker" && (
                  <KickerStatsTable
                    dataSource={
                      positions == "All"
                        ? kickerDatasource
                        : kickerDatasource.filter(
                            (data) => data.position == positions
                          )
                    }
                    pageLoading={pageLoading}
                  />
                )}
              </div>
              {/* <ExportCsvModal
                visible={showModal}
                setVisible={setShowModal}
                dataSource={dataSource}
                columns={column}
                seasonID={seasonID}
                setSeasonID={setSeasonID}
                setSeasonsList={setSeasonsList}
                seasonsLists={seasonsList}
                setGame={setGame}
                game={gamesList}
              /> */}
            </Card>
          </Card>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    coachProfile: state?.app?.account?.profile?.data?.details,
    schoolDetails: state?.app?.schools?.view?.data,
    positionsData: state?.app?.site?.gridironDetails?.data?.data?.positions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  gamesList: (details) => dispatch(global.redux.action.games.list(details)),
  updateGame: (details) => dispatch(global.redux.action.games.update(details)),
  gradesList: (details) => dispatch(global.redux.action.grades.list(details)),
  gradesStats: (details) =>
    dispatch(global.redux.action.grades.gradesStats(details)),
  CreateMvp: (details) => dispatch(global.redux.action.mvps.create(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
