import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

function FocusWindow({ children, title = 'WardBord Evaluation', width = 1000, height = 400, onClose }) {
  const [containerEl, setContainerEl] = useState(null);
  const newWindowRef = useRef(null);

  useEffect(() => {
    // Open a new browser window with the desired dimensions
    newWindowRef.current = window.open(
      '',
      title,
      `width=${width},height=${height},left=200,top=200,location=no, menubar=no, toolbar=no,`
    );

    // Create a container div and append it to the new window's document
    const container = newWindowRef.current.document.createElement('div');
    newWindowRef.current.document.body.appendChild(container);
    setContainerEl(container);

      // 1) Copy all the <link> and <style> tags from the main window’s head
  const styleSheets = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'));
    styleSheets.forEach((sheet) => {
      newWindowRef.current.document.head.appendChild(sheet.cloneNode(true));
    });

 
    // Optional: Add some basic styling to the new window
    newWindowRef.current.document.title = title;
    newWindowRef.current.document.body.style.margin = '0';
    newWindowRef.current.document.body.style.fontFamily = 'Arial, sans-serif';

    // Clean up: close the new window when the component unmounts
    const curWindow = newWindowRef.current;
    const handleBeforeUnload = () => {
      onClose && onClose();
    };
    curWindow.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      curWindow.removeEventListener('beforeunload', handleBeforeUnload);
      curWindow.close();
    };
  }, [title, width, height, onClose]);

  // If container is not ready yet, render nothing
  if (!containerEl) return null;
  // Use React Portal to render children into the container in the new window
  return ReactDOM.createPortal(children, containerEl);
}

export default FocusWindow;
